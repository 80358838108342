import { template as template_57385ce4392c4adcbb846de5fb945282 } from "@ember/template-compiler";
const FKControlMenuContainer = template_57385ce4392c4adcbb846de5fb945282(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
