import { template as template_e84ecf51209c48f0a17ce96c9cf50aba } from "@ember/template-compiler";
const SidebarSectionMessage = template_e84ecf51209c48f0a17ce96c9cf50aba(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
