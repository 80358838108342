import { template as template_e153b7bc3d78440b87c4600fc716edcd } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_e153b7bc3d78440b87c4600fc716edcd(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
