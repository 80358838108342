import { template as template_4ca952d10b89423c8cc23e1bec41128a } from "@ember/template-compiler";
const FKLabel = template_4ca952d10b89423c8cc23e1bec41128a(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
