import { template as template_829cd97208b743cc8f5b7dfc62354fcc } from "@ember/template-compiler";
const FKText = template_829cd97208b743cc8f5b7dfc62354fcc(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
